import { useState } from "react";

//components
import
{
	Text,
	Grid,
	Button,
	Image,
	cx,
	useEventSubscription,
	ToolButtonType,
	ToolButton
} from "@MoonLabsDev/dapp-core-lib";
import { ModalPaymentCreateInvoice } from "./Modals";

//framework
import { useDApp, MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents_Payment, usePayment } from "src/dApp/modules";

//styles
import styles from "./Payments.module.css";

export const PaymentInfo = ({className, style, onClickSettings}) =>
{
	//context
	const dApp = useDApp();
	const payment = usePayment();

	//funcitons
	const getUserOrg = () =>
	{
		const o = payment.getUserOrganization();
		if (!o)
		{
			return null;
		}
		return {...o};
	};

	//state
	const [showCreate, setShowCreate] = useState(() => false);
	const [orgInfo, setOrgInfo] = useState(() => getUserOrg());

	//effects
	useEventSubscription(ModuleEvents_Payment.orgData, () => setOrgInfo(getUserOrg()));

	return (
		<div
			className={cx([styles.paymentInfo, className])}
			style={style}
		>
			<ModalPaymentCreateInvoice
				show={showCreate}
				organization={0}
				onClose={() => setShowCreate(false)}
			/>

			<Image
				src="/assets/page/payment/CreditCard.png"
				style={{ width: "100%"}}
			/>

			<Grid
				gap="5px"
				className={styles.paymentInfo_owner}
			>
				<Text>
					{orgInfo
						? "Organization"
						: "Owner"
					}:
				</Text>
				<Text>
					{!orgInfo &&
						<>
							{MLFormat.formatAddress(dApp.account, true)}
						</>
					}

					{orgInfo &&
						<>
							{orgInfo.name === ""
								? MLFormat.formatAddress(orgInfo.owner, true)
								: orgInfo.name
							}
						</>
					}
				</Text>
			</Grid>

			<Button
			 	className={styles.paymentInfo_createInvoice}
				onClick={() => setShowCreate(true)}
			>
				Create Invoice
			</Button>

			{(onClickSettings && payment.isOrganizationOwner()) &&
				<ToolButton
					className={styles.paymentInfo_orgSettings}
					type={ToolButtonType.Settings}
					onClick={() => onClickSettings()}
				/>
			}
		</div>
	);
};