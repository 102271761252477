//components
import
{
	Card,
	CardBody,
	Text,
	TokenIcon,
	Flex,
	ToolButton,
	ToolButtonType,
	Grid,
	ValueTokenAmount,
	FormatFiatAmount,
	FormatDate,
	FormatPercent,
	useIsMobile,
	useDApp
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useStakingV1 } from "src/dApp/modules";

//styles
import styles from "../Farms.module.css";

const DetailsHeader = ({farm, onGoBack}) =>
{
	//context
	const dApp = useDApp();
	const staking = useStakingV1();

	//handler
	const handleGoBack = (_farmID) =>
	{
		onGoBack?.(_farmID);
	};

	return (
		<Flex justify="space-between">
			<ToolButton
				type={ToolButtonType.ArrowLeft}
				onClick={() => handleGoBack(farm.id)}
			/>

			<Grid gap="0px">
				<Flex>
					<TokenIcon
						className={styles.farmDetails_header_icon}
						token={farm.depositToken}
					/>
				</Flex>

				<Text
					color={3}
					align="center"
				>
					{farm.depositToken.getFullName()}
					{farm.vestingPeriod === 0
						? <></>
						: <>{" "}({MLFormat.formatDHMS(farm.vestingPeriod, true, true, true)})</>
					}
				</Text>

			</Grid>

			<ToolButton
				type={ToolButtonType.Contract}
				href={dApp.currentChain?.explorer?.contract?.replace("{address}", staking.address)}
				target="_blank"
			/>
		</Flex>
	);
};

const DetailList = ({title, children}) =>
{
	return (
		<Grid
			gap="0px"
			align="start"

		>
			<Text
				size={-1}
				color={3}
			>
				{title}:
			</Text>
			{children}
		</Grid>
	);
};

const DetailStat = ({title, extra, children}) =>
{
	return (
		<Flex
			justify="space-between"
			className={styles.detailsLine}
		>
			<Flex>
				<Text
					size={-1}
					color={1}
				>
					{title}:
				</Text>
				{extra}
			</Flex>

			<Text
				size={-1}
				color={3}
			>
				{children}
			</Text>
		</Flex>
	);
};

const Details_Staking = ({farm}) =>
{
	return (
		<DetailList title="Staking details">
			<DetailStat
				title="Reward Token"
			>
				{farm.rewardToken.symbol}
			</DetailStat>

			<DetailStat
				title="Total Tokens Deposit"
			>
				<ValueTokenAmount
					token={farm.depositToken}
					value={farm.totalDeposit}
					shorten={true}
				/>
			</DetailStat>

			<DetailStat
				title="Total Value Locked"
			>
				<FormatFiatAmount
					value={farm.totalDepositUSD}
				/>
			</DetailStat>

			<DetailStat
				title="Vesting"
			>
				{MLFormat.formatDHMS(farm.vestingPeriod, true, true, true)}
			</DetailStat>

			<DetailStat
				title="APR"
			>
				<FormatPercent
					value={farm.apr}
				/>
			</DetailStat>
		</DetailList>
	);
};

const Details_User = ({farm}) =>
{
	return (
		<DetailList title="User details">
			<DetailStat
				title="Deposit"
			>
				<FormatFiatAmount
					value={farm.userDepositUSD}
				/>
			</DetailStat>

			<DetailStat
				title="Deposit Tokens"
			>
				<ValueTokenAmount
					token={farm.depositToken}
					value={farm.userDeposit}
					shorten={true}
				/>
			</DetailStat>

			<DetailStat
				title="Pending Reward"
			>
				<ValueTokenAmount
					token={farm.rewardToken}
					value={farm.userPending}
					shorten={true}
				/>
			</DetailStat>

			<DetailStat
				title="Claimed Reward"
			>
				<ValueTokenAmount
					token={farm.rewardToken}
					value={farm.userClaimed}
					shorten={true}
				/>
			</DetailStat>

			<DetailStat
				title="Vested until"
			>
				{farm.userVestedUntil === null
					? <>???</>
					: <FormatDate
						value={farm.userVestedUntil}
						showTime={true}
					/>
				}
			</DetailStat>
		</DetailList>
	);
};

export const StakingV1Details = ({farm, onGoBack}) =>
{
	const isMobile = useIsMobile();
	return (
		<Card>
			<CardBody>
				<Grid>
					<DetailsHeader
						farm={farm}
						onGoBack={onGoBack}
					/>

					<Grid
						cols={isMobile ? 1 : 2}
						responsive={{
							xs: {
								cols: 1
							},
							md: {
								cols: 2
							}
						}}
						align="start"
					>
						<Details_Staking farm={farm} />
						<Details_User farm={farm} />
					</Grid>
				</Grid>
			</CardBody>
		</Card>
	);
};