//components
import
{
	Card,
	CardBody,
	Grid,
	Flex,
	Text,
	TokenIcon,
	FormatFiatAmount,
	FormatPercent,
	FormatTokenAmount,
	Emblem,
	EmblemColor
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { MLFormat } from "@MoonLabsDev/dapp-core-lib";

//classes
import { StakingV1Tags } from "../../../../classes/StakingV1";

//styles
import styles from "../Farms.module.css";

const StakingStat = ({title, subChildren, children}) =>
{
	return (
		<Grid
			gap="0px"
			style=
			{
				{
					width: "33.33%",
					minWidth: "100px"
				}
			}
		>
			<Text
				align="center"
			>
				{title}
			</Text>

			<Text
				color={2}
				size={-1}
				align="center"
			>
				{children}
			</Text>

			{subChildren}
		</Grid>
	);
};

const StakingTags = ({farm}) =>
{
	const protocolColor = EmblemColor.yellow;
	const tagColors =
	[
		{ tag: StakingV1Tags.POOL, color: EmblemColor.green },
		{ tag: StakingV1Tags.FARM, color: EmblemColor.green },

		{ tag: StakingV1Tags.STABLE, color: EmblemColor.blue },

		{ tag: StakingV1Tags.VESTED, color: EmblemColor.violet },

		{ tag: StakingV1Tags.DEVELOPER, color: EmblemColor.red }
	];

	return (
		<Flex
			gap="5px"
			justify="flex-start"
		>
			{farm.tags.map(t =>
				{
					let tag = t;
					let clr = EmblemColor.gray;
					if (t.includes("@"))
					{
						tag = t.replace("@", "");
						clr = protocolColor;
					}
					else
					{
						clr = tagColors.find(tc => tc.tag === t)?.color ?? clr;
					}

					return (
						<Text
							key={tag}
							size={-1}
						>
							<Emblem
								color={clr}
							>
								{tag}
							</Emblem>
						</Text>
					);
				})
			}
		</Flex>
	);
};

const StakingStat_TVL = ({farm}) =>
{
	return (
		<StakingStat title="TVL">
			<FormatFiatAmount
				value={farm.totalDepositUSD}
			/>
		</StakingStat>
	);
};

const StakingStat_APR = ({farm}) =>
{
	return (
		<StakingStat title="APR">
			<Flex>
				<FormatPercent
					value={farm.apr}
				/>
			</Flex>
		</StakingStat>
	);
};

const StakingStat_DailyAPR = ({farm}) =>
{
	return (
		<StakingStat title="Daily APR">
			<Flex>
				<FormatPercent
					value={farm.dailyAPR}
				/>
			</Flex>
		</StakingStat>
	);
};

const StakingStat_APY = ({farm}) =>
{
	return (
		<StakingStat title="APY">
			<Flex>
				<FormatPercent
					value={farm.apy}
				/>
			</Flex>
		</StakingStat>
	);
};

const StakingStat_UserDeposit = ({farm}) =>
{
	return (
		<StakingStat
			title="Your Deposit"
		>
			<FormatTokenAmount
				token={farm.depositToken}
				value={farm.userDeposit}
			/>
		</StakingStat>
	);
};

export const StakingV1Item = ({farm, onSelectStaking}) =>
{
	//handler
    const handleSelectStaking = (e) =>
    {
        e.stopPropagation();
        onSelectStaking?.(farm.id);
    };

	return (
		<Card onClick={(e) => handleSelectStaking(e)}>
			<CardBody className={styles.farmItem_wrapper}>
				<Flex
					className={styles.farmItem}
					justify="flex-start"
					wrap="wrap"
				>
					<TokenIcon
						className={styles.farmItem_icon}
						token={farm.depositToken}
					/>

					<Grid
						className={styles.farmItem_info}
						gap="0px"
					>
						<Text>
							{farm.depositToken.getFullName()}
							{farm.vestingPeriod === 0
								? <></>
								: <>{" "}({MLFormat.formatDHMS(farm.vestingPeriod, true, true, true)})</>
							}
						</Text>
						<StakingTags farm={farm} />
					</Grid>

					<Flex
						gap="0px"
						style={{ flexGrow: 1 }}
					>
						<StakingStat_TVL farm={farm} />
						<StakingStat_APR farm={farm} />
						<StakingStat_UserDeposit farm={farm} />
					</Flex>
				</Flex>
			</CardBody>
		</Card>
	);
};