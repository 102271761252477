//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { Payment } from "../classes/Payment";

export const ModuleEvents =
{
	init: "payment_init",
    userData: "payment_userInfo",
	orgData: "payment_orgInfo"
};

export class Module_Payment extends DAppModule
{
    static moduleName = "payment";
    constructor()
    {
        super(m => m.payment);
        this.payment = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.payment === null)
        {
            return;
        }

        //userInfo
        if (dApp.account !== null)
        {
			//user/issuer
			await MLUtils.measureTime(`TM: Payment => UserInfoBase`, async () =>
            {
                await this.payment.batch_userInfoBase();
            });
            await MLUtils.measureTime(`TM: Payment => UserInfo`, async () =>
            {
                await this.payment.batch_userInfo();
            });
        }

		//invoice
		await MLUtils.measureTime(`TM: Payment => InvoiceInfo`, async () =>
		{
			await this.payment.batch_invoiceInfo();
		});

		//organization
		await MLUtils.measureTime(`TM: Payment => OrganizationInfo`, async () =>
		{
			await this.payment.batch_organizationInfo();
		});
    }

    async onLoad(dApp)
    {
        if (!Module_Payment.availableOnChain(dApp.currentChain))
        {
            return;
        }
        const addr = dApp.currentChain?.modules?.payment;
        dApp.log(false, `Loading Payment [${addr}]`);
        this.payment = new Payment(dApp, addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.payment;
        const result = (addr !== undefined
            && addr !== "");

        return result;
    }
}

export const usePayment = () => useModule(Module_Payment);

const currentModule = new Module_Payment();
export default currentModule;