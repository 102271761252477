import { useMemo, useState, useEffect } from "react";

//components
import { InputTokenAmount } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents_StakingV1, useStakingV1 } from "../../../../modules";

export const InputTokenAmount_StakingV1Balance = (props) =>
{
    //context
    const staking = useStakingV1();

    //state
	const farm = useMemo(() => staking.findFarm(props.farmID), [props.farmID]);
	const [available, setAvailable] = useState(() => farm?.userDeposit);

    //handler
    const handleUpdateBalance = (_data) =>
    {
        if (_data.detail?.id !== props.farmID)
        {
            return;
        }
        setAvailable(farm?.userDeposit);
    };

    //effects
    useEffect(() => setAvailable(farm?.userDeposit), [farm]);
    useEventSubscription(ModuleEvents_StakingV1.userData, handleUpdateBalance, [farm]);

    return (
        <InputTokenAmount
            token={farm.depositToken}
            value={props.value}
            showAvailable={true}
            available={available}
            availableText={"Deposit"}
            showMax={props.showMax}
            showInWallet={props.showInWallet}
            allowTokenSelection={props.allowTokenSelection}
            readOnly={props.readOnly}
            placeholder={props.placeholder}
            onChange={props.onChange}
            onSelect={props.onSelect}
            onBlur={props.onBlur}
        />
    );
};