import { useEffect, useState } from "react";

//components
import
{
    Modal,
    Grid,
    Flex,
	Text,
	Input,
    InputTokenAmount_Balance,
	ModalButtonContainer,
	ModalButton,
	CheckTransactionButton_Balance
} from "@MoonLabsDev/dapp-core-lib";
import {PaymentOrganizationInfo} from "../PaymentOrganizationInfo";

//framework
import { Translator as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3, MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { usePayment, ModuleEvents_Payment } from "../../../../modules";

//classes
import { PaymentStatus } from "src/dApp/classes";

//hooks
import { useDApp, useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

const InfoWithHeader = ({title, children}) =>
{
	return (
		<Grid gap="0">
			<Text size={-1}>
				{title}:
			</Text>
			{children}
		</Grid>
	);
}

const InfoLine = ({title, children}) =>
{
	return (
		<Flex justify="space-between">
			<Text
				size={-1}
				color={1}
			>
				{title}:
			</Text>
			<Text
				size={-1}
				color={2}
			>
				{children}
			</Text>
		</Flex>
	);
}

export const ModalPaymentViewInvoice = ({ show, onClose, onQR, globalInvoiceId }) =>
{
    //hooks
    const dApp = useDApp();
    const payment = usePayment();

	//function
	const getInvoice = (_id) =>
	{
		const i = payment.findGlobalInvoice(_id);
		return (!!i
			? {...i}
			: null
		);
	}

	//state
	const [invoice, setInvoice] = useState(getInvoice(globalInvoiceId));

	//handler
    const handleClose = () =>
    {
        onClose?.();
    };
    const handleDelete = () =>
    {
		payment.cancelInvoice(invoice).send();
        handleClose();
    };
	const handlePay = () =>
    {
		payment.payInvoice(invoice).send();
        handleClose();
    };
	const handleQR = () =>
    {
		onQR?.();
    };

	//effect
	useEventSubscription(
		ModuleEvents_Payment.userData,
		() => setInvoice(getInvoice(globalInvoiceId)),
		[globalInvoiceId]
	);
	useEffect(() =>
		{
			if (!!invoice)
			{
				payment.pollGlobalInvoice(invoice, false);
			}
			payment.pollGlobalInvoice(globalInvoiceId, true);
		},
		[globalInvoiceId]
	);

    return (
        <Modal
            show={show}
            header={t("modal_paymentView_title")}
            footer=
            {
                <ModalButtonContainer>
					{invoice?.status === PaymentStatus.CREATED &&
						<>
							{(invoice?.receiver !== dApp.account || dApp.config.page.devMode) &&
								<CheckTransactionButton_Balance
									as={ModalButton}
									token={invoice?.token}
									amount={invoice?.amount}
									approveFor={payment.address}
									onClick={() => handlePay()}
								>
									{t("modal_paymentView_pay")}
								</CheckTransactionButton_Balance>
							}

							{(invoice?.receiver === dApp.account || dApp.config.page.devMode) &&
								<ModalButton onClick={() => handleQR()}>
									{t("modal_paymentView_qr")}
								</ModalButton>
							}
						</>
					}

					<ModalButton
						onClick={() => handleClose()}
						color={-1}
					>
                        {t("dismiss")}
                    </ModalButton>
                </ModalButtonContainer>
            }
            onClose={() => handleClose()}
        >
            <Grid>
				{!invoice?.initialized &&
					<Text align="center">
						Loading...
					</Text>
				}

				{!!invoice?.initialized &&
					<>
						<Grid gap="0">
							<InfoLine title={"Global Invoice ID"}>
								{globalInvoiceId}
							</InfoLine>
							<InfoLine title={"Issuer Invoice ID"}>
								{invoice.id}
							</InfoLine>
							<InfoLine title={"Created at"}>
								{MLFormat.formatDateTime(invoice.createdAt)}
							</InfoLine>

							{invoice.status === PaymentStatus.PAID &&
								<>
									<InfoLine title={"Closed at"}>
										{MLFormat.formatDateTime(invoice.closedAt)}
									</InfoLine>

									<InfoLine title={"Payer"}>
										{MLFormat.formatAddress(invoice?.payer, true)}
									</InfoLine>
								</>
							}
						</Grid>

						<InfoWithHeader title={"Receiver"}>
							{invoice.issuerId.cmp(MLWeb3.toBN(0)) === -1
								? <PaymentOrganizationInfo
									organizationId={-parseInt(invoice.issuerId.toString(10))}
								/>
								: <Input
									value={MLFormat.formatAddress(invoice?.receiver, true)}
									readonly={true}
								/>
							}
						</InfoWithHeader>

						<InfoWithHeader title={"Memo"}>
							<Input
								value={invoice?.memo}
								readonly={true}
							/>
						</InfoWithHeader>

						<InputTokenAmount_Balance
							token={invoice?.token}
							value=
							{
								(!!invoice
									? MLWeb3.convertTokenBN_FloatString(invoice?.amount, invoice?.token || dApp.wrappedCoin, true)
									: ""
								)
							}
							showInWallet={true}
							showMax={false}
							readonly={true}
						/>
					</>
				}
            </Grid>
        </Modal>
    );
};