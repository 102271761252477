import { isMobile } from "react-device-detect";

//components
import
{
  Grid,
  Text,
  Link,
  Modal,
  ModalButton,
  ModalButtonContainer
} from "@MoonLabsDev/dapp-core-lib";
import { QRCodeField } from "./QRCodeField";

//framework
import { DApp, ToastType } from "@MoonLabsDev/dapp-core-lib";

export const ModalQRCode = ({ show, url, title, header, onClose }) =>
{
  //handler
  const handleShare = (_url) =>
  {
	if (isMobile)
	{
    	navigator.share({ url: _url });
	}
	else
	{
		navigator.clipboard.writeText(_url);
		DApp.makeToast(
			"Copied to clipboard!",
			"Invoice Link",
			ToastType.info
		);
	}
  };

  return (
    <Modal
      	show={show}
      	header={header}
      	footer=
		{
			<ModalButtonContainer>
				<ModalButton onClick={() => handleShare(url)}>
					Share
				</ModalButton>
				<ModalButton
					color={-1}
					onClick={onClose}
				>
					Dismiss
				</ModalButton>
			</ModalButtonContainer>
		}
      onClose={onClose}
    >
		<Grid>
			<Text align="center">
				{title}:
				<br />
			</Text>

			<QRCodeField data={url} />

			<Text align="center">
				<Link
					href={url}
					target="_blank"
				>
					Direct Link
				</Link>
			</Text>
		</Grid>
    </Modal>
  );
};
