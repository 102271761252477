//dApp
import { configPage as config } from "@MoonVault/dapp-widgets-lib";
import { Filters } from "./filters";

//pages (test)
import
{
	Page_debugInfo,
	Page_test_general,
	Page_test_extended,
	Page_test_cards,
	Page_test_charts,
	Page_test_modals
} from "@MoonLabsDev/dapp-sdk-lib";

//pages
import
{
	Page_soon,
	Page_home,
	Page_swap,
	Page_earn_vaults,
	Page_earn_vaultDetails,
	Page_payment,
	Page_organization,
	Page_earn_farms,
	Page_earn_farmDetails
} from "../../pages";

//modules
import
{
	Module_Payment,
	Module_StakingV1
} from "../modules";

const devMode = false;
const configPage =
{
	...config,

	title: "OneImmortl",
	description: "One Lifestyle",
	themeColor: "#000000",
	logo: "/assets/page/logo.jpg",
	devMode: devMode,
	fetchData:
	{
		routers: true,
		tokens: true,
		vaults: true
	},
	fetchDataFilterValues:
	{
		vaults: Filters
	},
	socials:
	{
		discord: "https://discord.com/invite/qTpqVvEyXG",
		telegram: "https://t.me/theoneimmortlchat",
		twitter: "https://twitter.com/one_immortl"
	},
	modules:
	[
		...config.modules,
		Module_Payment,
		Module_StakingV1
	],

	preconnect:
    [
        { link: "https://fonts.googleapis.com" },
        { link: "https://fonts.gstatic.com", crossOrigin: true }
    ],
	fonts:
    [
		"https://fonts.googleapis.com/css2?family=Poppins:wght@300;400;500;600&display=swap"
    ],
    pages:
    [
        { path: "home", page: Page_home },
        { path: "debugInfo", page: Page_debugInfo },

        { path: "tests/general", page: Page_test_general },
		{ path: "tests/extended", page: Page_test_extended },
        { path: "tests/cards", page: Page_test_cards },
        { path: "tests/charts", page: Page_test_charts },
        { path: "tests/modals", page: Page_test_modals },

        { path: "swap", page: Page_swap },

		{ path: "earn/vaults", page: Page_earn_vaults },
		{ path: "earn/vaultDetails", page: Page_earn_vaultDetails },
		{ path: "earn/farms", page: Page_earn_farms },
		{ path: "earn/farmDetails", page: Page_earn_farmDetails },

		{ path: "payment", page: Page_payment },
		{ path: "organization", page: Page_organization }
    ]
};

export default configPage;