
import { useState, useMemo } from "react";

//components
import
{
	Text,
	Grid,
	Card,
	CardBody,
	Flex
} from "@MoonLabsDev/dapp-core-lib";
import
{
	InputTokenAmount_StakingV1Balance,
	CheckTransactionButton_StakingV1Balance,
	ButtonStakingV1BuySellToken
} from "../Misc";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//module
import { useStakingV1 } from "../../../../modules";

export const StakingV1Withdraw = ({farmID}) =>
{
	//context
	const staking = useStakingV1();

	//state
	const farm = useMemo(() => staking.findFarm(farmID), [farmID]);
	const [amount, setAmount] = useState(() => "");

	//handler
	const handleWithdraw = (_farm, _amount) =>
	{
		const a = MLWeb3.convertFloatString_TokenBN(_amount, _farm.depositToken);
		staking.withdraw(_farm.id, a).trySend();
	};

	return (
		<Card>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						alignItems="flex-end"
					>
						<Text
							color={2}
						>
							Withdraw:
						</Text>

						<ButtonStakingV1BuySellToken
							buy={false}
							token={farm.depositToken}
						/>
					</Flex>

					<InputTokenAmount_StakingV1Balance
						farmID={farm.id}
						value={amount}
						onChange={(e, v) => setAmount(v)}
					/>

					<CheckTransactionButton_StakingV1Balance
						farmID={farm.id}
						amount={MLWeb3.convertFloatString_TokenBN(amount, farm.depositToken)}
						insufficientError="Insufficient Deposit"
						onClick={() => handleWithdraw(farm, amount)}
					>
						Withdraw
					</CheckTransactionButton_StakingV1Balance>
				</Grid>
			</CardBody>
		</Card>
	);
};