import { useEffect, useState, useMemo } from "react";

//components
import { CheckTransactionButton } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents_StakingV1, useStakingV1 } from "../../../../modules";

export const CheckTransactionButton_StakingV1Balance = (props) =>
{
	//context
    const staking = useStakingV1();

    //state
	const farm = useMemo(() => staking.findFarm(props.farmID), [props.farmID]);
	const [balance, setBalance] = useState(() => farm?.userDeposit);

    //handler
    const handleUpdateBalance = (_data) =>
    {
        if (_data.detail?.id !== props.farmID)
        {
            return;
        }
        setBalance(farm?.userDeposit);
    };

    //effects
    useEffect(() => setBalance(farm?.userDeposit), [farm]);
    useEventSubscription(ModuleEvents_StakingV1.userData, handleUpdateBalance, [farm]);

    return (
        <CheckTransactionButton
			{...props}
			maxAmount={balance}
            onClick={props.onClick}
        >
			{props.children}
        </CheckTransactionButton>
    );
};