//components
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule,
	PageWrapper_StakingV1_farms
} from "../../dApp/components";

export const Page_earn_farms = () =>
{
    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Farms"
					backgroundImage="url(/assets/page/earn/farms/header.png)"
				>
					Get rewards for staking your tokens!
				</MVPageHeader>
			}
		>
            <MVCheckModule module={"stakingV1"}>
				<PageWrapper_StakingV1_farms />
			</MVCheckModule>
        </MVPage>
    );
}