//components
import
{
	MVPage,
	MVCheckModule,
	PageWrapper_StakingV1_farmDetails
} from "../../dApp/components";

export const Page_earn_farmDetails = () =>
{
    return (
		<MVPage>
            <MVCheckModule module="stakingV1">
				<PageWrapper_StakingV1_farmDetails />
			</MVCheckModule>
        </MVPage>
    );
}