
import { useMemo } from "react";

//components
import { Button } from "@MoonLabsDev/dapp-core-lib";

//framework
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

export const ButtonStakingV1BuySellToken = ({buy, token, internalSwapLink}) =>
{
	//context
	const dApp = useDApp();

	//functions
	const getText = (_buy, _token) =>
	{
		if (_token.isLPToken())
		{
			//LP token
			return (_buy
				? "Add liquidity"
				: "Remove liquidity"
			);
		}
		else
		{
			//normal token
			return (_buy
				? "Buy token"
				: "Sell token"
			);
		}
	};
	const getLink = (_buy, _token, _internalSwapLink) =>
	{
		let internal = false;
		let link = "";

		//use native swap
		if (!!_internalSwapLink
			&& (!_token
				|| !_token.data.hideInSwap))
		{
			internal = true;
			link = _internalSwapLink.replace("{token}", _token?.address ?? "");
		}

		//check for external swap
		const router = dApp.findRouter(_token.router);
		if (_token.isLPToken())
		{
			link = (_buy
				? router.linkAddLiquidity
				: router.linkRemoveLiquidity
			).replace("{token0}", _token.token0Address).replace("{token1}", _token.token1Address);
		}
		else
		{
			//normal token
			const from = (_buy
				? ""
				: _token.address
			);
			const to = (_buy
				? _token.address
				: ""
			);
			link = router.linkSwap.replace("{from}", from).replace("{to}", to);
		}

		return {
			href: link,
			internal
		}
	};

	//state
	const text = useMemo(() => getText(buy, token), [buy, token]);
	const link = useMemo(() => getLink(buy, token, internalSwapLink), [buy, token]);

	return (
		<Button
			size={-1}
			href={link.href}
			nav={link.interal}
			target="_blank"
		>
			{text}
		</Button>
	);
};