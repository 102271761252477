import { useState, useMemo } from "react";

//components
import
{
	Text,
	Grid,
	Card,
	CardBody,
	CheckTransactionButton_Balance,
	InputTokenAmount_Balance,
	Flex,
} from "@MoonLabsDev/dapp-core-lib";
import { ButtonStakingV1BuySellToken } from "../Misc";

//framework
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//module
import { useStakingV1 } from "../../../../modules";

export const StakingV1Deposit = ({farmID}) =>
{
	//context
	const staking = useStakingV1();

	//state
	const farm = useMemo(() => staking.findFarm(farmID), [farmID]);
	const [amount, setAmount] = useState(() => "");

	//handler
	const handleDeposit = (_farm, _amount) =>
	{
		const a = MLWeb3.convertFloatString_TokenBN(_amount, _farm.depositToken);
		staking.deposit(_farm.id, a).trySend();
	};

	return (
		<Card>
			<CardBody>
				<Grid>
					<Flex
						justify="space-between"
						alignItems="flex-end"
					>
						<Text
							color={2}
						>
							Deposit:
						</Text>

						<ButtonStakingV1BuySellToken
							buy={true}
							token={farm.depositToken}
						/>
					</Flex>

					<InputTokenAmount_Balance
						token={farm.depositToken}
						value={amount}
						onChange={(e, v) => setAmount(v)}
					/>

					<CheckTransactionButton_Balance
						token={farm.depositToken}
						amount={MLWeb3.convertFloatString_TokenBN(amount, farm.depositToken)}
						approveFor={staking.address}
						onClick={() => handleDeposit(farm, amount)}
					>
						Deposit
					</CheckTransactionButton_Balance>
				</Grid>
			</CardBody>
		</Card>
	);
};