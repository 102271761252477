import { useState } from "react";
import { useNavigate } from "react-router-dom";

//components
import { Grid } from "@MoonLabsDev/dapp-core-lib";
import
{
	StakingV1Overview,
	StakingV1Metric,
	StakingV1MetricType,
	StakingV1Filter
} from ".";

export const PageWrapper_StakingV1_farms = ({ detailsLink = `/earn/farmDetails?farm={id}`}) =>
{
	//context
	const navigate = useNavigate();

	//state
	const [filter, setFilter] = useState(() => {});

	//handler
	const handleSelectStaking = (_farmID) =>
	{
		navigate(detailsLink.replace("{id}", _farmID));
	};
	const handleFilterChange = (_filter) =>
	{
		setFilter(_filter);
	};

    return (
		<>
			<Grid cols={3}>
				<StakingV1Metric
					type={StakingV1MetricType.USER_REWARDS_DAY}
				/>
				<StakingV1Metric
					type={StakingV1MetricType.USER_REWARDS_YEAR}
				/>
				<StakingV1Metric
					type={StakingV1MetricType.USER_DEPOSIT}
				/>
			</Grid>

			<StakingV1Filter
				filter={filter}
				onChange={handleFilterChange}
			/>

			<StakingV1Overview
				filter={filter}
				onSelectStaking={handleSelectStaking}
			/>
        </>
    );
}