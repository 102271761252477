import { useState, useEffect } from "react";

//components
import { Grid } from "@MoonLabsDev/dapp-core-lib";
import { StakingV1Item } from "./StakingV1Item";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useStakingV1, ModuleEvents_StakingV1 } from "../../../../modules";

export const StakingV1Overview = ({filter, onSelectStaking}) =>
{
	//context
	const vault = useStakingV1();

	//functions
	const getStakingData = (_vaults) =>
	{
		return _vaults.map(v =>
		{
			return {
				id: v.id,
				name: v.name,
				depositToken: v.depositToken,
				totalDepositUSD: v.totalDepositUSD,
				userDeposit: v.userDeposit,
				userDepositUSD: v.userDepositUSD,
				tags: v.tags,

				dailyAPR: v.dailyAPR,
				apr: v.apr,
				apy: v.apy,
				vestingPeriod: v.vestingPeriod
			};
		});
	};

	//state
	const [vaults, setStakings] = useState(() => vault.applyFilter(filter));
	const [vaultData, setStakingData] = useState(() => getStakingData(vaults));

	//effects
	useEffect(() => setStakings(vault.applyFilter(filter)), [filter]);
	useEventSubscription(ModuleEvents_StakingV1.initFarms, () => setStakings(vault.applyFilter(filter)), [filter]);
	useEffect(() => setStakingData(getStakingData(vaults)), [vaults]);
	useEventSubscription(
		[
			ModuleEvents_StakingV1.farmData,
			ModuleEvents_StakingV1.userData
		],
		() => setStakingData(getStakingData(vaults)),
		[vaults]
	);

	return (
		<Grid>
			{vaultData.map(v =>
				<StakingV1Item
					key={`vault_${v.id}`}
					farm={v}
					onSelectStaking={onSelectStaking}
				/>
			)}
		</Grid>
	);
};