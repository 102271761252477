import { useEffect, useState } from "react";

//components
import
{
	SelectBox,
	Flex,
	Text
} from "@MoonLabsDev/dapp-core-lib";
import { PaymentInvoiceList } from "./PaymentInvoiceList";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { usePayment, ModuleEvents_Payment } from "src/dApp/modules";

const InvoiceType =
{
	CREATED: true,
	PAID: false
};

export const PaymentInvoiceList_Select = ({className, style}) =>
{
	//context
	const payment = usePayment();

	//functions
	const getInvoices = (_created) =>
	{
		let i = (_created
			? [
				...payment.userInvoicesCreated,
				...payment.userPaidInvoices
			]
			: [...payment.userInvoicesPaid]
		);
		i.sort((a, b) => b.createdAt.getTime() - a.createdAt.getTime());
		return i;
	};

	//state
	const [invoiceType, setInvoiceType] = useState(() => InvoiceType.CREATED);
	const [invoices, setInvoices] = useState(() => getInvoices(invoiceType));

	//effects
	useEventSubscription([ModuleEvents_Payment.userData], () => setInvoices(getInvoices(invoiceType)), [invoiceType]);
	useEffect(() =>
	{
		setInvoices(getInvoices(invoiceType))
	}, [invoiceType]);

	return (
		<PaymentInvoiceList
			className={className}
			style={style}
			title=
			{
				<Flex justify="space-between">
					<Text>
						Your Invoices:
					</Text>
					<SelectBox
						placeholder="Type"
						options=
						{
							[
								{
									value: InvoiceType.CREATED,
									text: "Requested"
								},
								{
									value: InvoiceType.PAID,
									text: "Paid"
								}
							]
						}
						value={invoiceType}
						onSelect={(v) => setInvoiceType(v)}
					/>
				</Flex>
			}
			invoices={invoices}
			isPaid={invoiceType === InvoiceType.PAID}
		/>
	);
};