import { useState } from "react";
import { useSearchParams } from "react-router-dom";

//components
import
{
    Grid,
    Flex
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule,
	PaymentInfo,
	PaymentInvoiceList_Select,
	ModalPaymentViewInvoice,
	ModalPaymentQR
} from "../../dApp/components";

//framework
import { useEventSubscription } from "@MoonLabsDev/dapp-core-lib";

//modules
import { usePayment, ModuleEvents_Payment } from "src/dApp/modules";

const PaymentContent = () =>
{
	//context
	const payment = usePayment();
	const [searchParams] = useSearchParams();

	//function
	const getInvoice = (_params) =>
	{
		if (!_params.has("invoice"))
		{
			return null;
		}
		return parseInt(_params.get("invoice"));
	}

	//state
	const [selectedInvoice, setSelectedInvoice] = useState(() => getInvoice(searchParams));
	const [qrInvoice, setQRInvoice] = useState(() => null);

    return (
        <Flex style={{ width: "100%"}}>
			{(selectedInvoice !== null && qrInvoice === null) &&
				<ModalPaymentViewInvoice
					show={!!selectedInvoice}
					globalInvoiceId={selectedInvoice}
					onClose={() => setSelectedInvoice(null)}
					onQR={() => setQRInvoice(selectedInvoice)}
				/>
			}
			{qrInvoice !== null &&
				<ModalPaymentQR
					show={!!qrInvoice}
					globalInvoiceId={qrInvoice}
					onClose={() => setQRInvoice(null)}
				/>
			}

            <Grid
                style=
                {
                    {
                        flexGrow: 1,
						alignItems: "start"
                    }
                }
				responsive=
				{
					{
						lg:
						{
							cols: 2
						}
					}
				}
            >
				<PaymentInfo />
				<PaymentInvoiceList_Select
					style=
					{
						{
							height: "100%",
							maxHeigth: "350px"
						}
					}
				/>
            </Grid>
        </Flex>
    );
};

export const Page_organization = () =>
{
	return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Immortl Payment"
					backgroundImage="url(/assets/page/payment/header.jpg)"
				>
					Request or make payments with crypto currencies
				</MVPageHeader>
			}
		>
            <MVCheckModule module="payment">
				<PaymentContent />
			</MVCheckModule>
        </MVPage>
    );
}