//components
import { ModalQRCode } from "../../../QR/ModalQRCode";

//framework
import { Translator as t } from "@MoonLabsDev/dapp-core-lib";

export const ModalPaymentQR = ({ show, onClose, globalInvoiceId}) =>
{
    return (
		<ModalQRCode
			show={show}
			allowBig={true}
			header={t("modal_paymentQR_title")}
			title={t("modal_paymentQR_info")}
			url={`${window.location.origin}/payment?invoice=${globalInvoiceId}`}
			onClose={onClose}
		/>
    );
};