//components
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";
import { PageWrapper_MoonSwap, MoonVaultWidgets } from "@MoonVault/dapp-widgets-lib";

export const Page_swap = () =>
{
	return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Immortl Swap"
					backgroundImage="url(/assets/page/swap/header.jpg)"
				>
					Find the cheapest swap across multiple decentralized exchanges
				</MVPageHeader>
			}
		>
            <MVCheckModule module={MoonVaultWidgets.swap}>
				<PageWrapper_MoonSwap />
			</MVCheckModule>
        </MVPage>
    );
}