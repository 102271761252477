import { useEffect, useState } from "react";

//components
import
{
    Text,
    Grid,
    Image,
	Link,
    Card,
    CardBody,
    Flex,
	Carousel,
	CardHeader,
	CardRibbon
} from "@MoonLabsDev/dapp-core-lib";
import
{
	MVPage,
	SectionHeader
} from "../dApp/components";

//framework
import
{
	useIsMobile,
	cx,
	MLFormat,
	MLUtils
} from "@MoonLabsDev/dapp-core-lib";

//stlyes
import styles from "src/pages/Pages.module.css";

const Header = () =>
{
	const isMobile = useIsMobile();

	//const functions

    return (
        <Flex
			direction="row"
			className={cx([styles.homeHeader])}
        >
			<Flex direction="column" basis={isMobile ? "75%": "38%"}>
				<Text
					size={2}
					color={3}
					style={{ textShadow: "rgb(255 211 86) 0px 0px 10px" }}
				>
					Welcome to One Immortl
				</Text>
				<Text size={0} style={{marginTop: "auto"}}>
					Multi-brand loyalty program with an integrated online and blockchain payment system
				</Text>
			</Flex>
        </Flex>
    );
};

export const Page_home = () =>
{
	//context
	const isMobile = useIsMobile();

	//functions
	const initVideo = () =>
	{
		const vid = document.getElementById('rotatingLogo');
		if (vid)
		{
			vid.play();
			vid.addEventListener('ended', () =>
			{
				vid.currentTime = 0;
				vid.play();
			}, false);
		}
	};

    return (
		<MVPage>

			<div onLoad={() => initVideo()}>
				<video
					id="rotatingLogo"
					className={styles.homeBackground}
					autoPlay loop muted playsInline
					style=
					{
						{
							...(isMobile
								? { height: "100vh" }
								: { objectFit: "cover" }
							)
						}
					}
				>
					<source src="assets/page/home/background.mp4" />
				</video>
			</div>

			<Header />

		</MVPage>
    );
}