//framework
import { DAppModule, MLUtils, useModule } from "@MoonLabsDev/dapp-core-lib";

//classes
import { StakingV1 } from "../classes/StakingV1";

export const ModuleEvents =
{
	init: "stakingV1_init",
	initFarms: "stakingV1_initFarms",
	farmData: "stakingV1_farmData",
    userData: "stakingV1_userData"
};

export class Module_StakingV1 extends DAppModule
{
    static moduleName = "stakingV1";
    constructor()
    {
        super(m => m.stakingV1);
        this.stakingV1 = null;
    }

    async onRefreshChainData(dApp)
    {
        if (this.stakingV1 === null)
        {
            return;
        }

		//init
		if (!this.stakingV1.initialized)
		{
			await MLUtils.measureTime(`TM: StakingV1 => Init`, async () =>
			{
				await this.stakingV1.batch_init();
			});
		}

		//init farms
		if (!this.stakingV1.initializedFarms)
		{
			await MLUtils.measureTime(`TM: StakingV1 => InitFarms`, async () =>
			{
				await this.stakingV1.batch_initFarms();
			});
		}

		//farms
		await MLUtils.measureTime(`TM: StakingV1 => FarmData`, async () =>
		{
			await this.stakingV1.batch_farmData();
		});

        //userInfo
        if (dApp.account !== null)
        {
            await MLUtils.measureTime(`TM: StakingV1 => UserData`, async () =>
            {
                await this.stakingV1.batch_userData();
            });
        }
    }

    async onLoad(dApp)
    {
        if (!Module_StakingV1.availableOnChain(dApp.currentChain))
        {
            return;
        }
        const addr = dApp.currentChain?.modules?.stakingV1;
        dApp.log(false, `Loading StakingV1 [${addr}]`);
        this.stakingV1 = new StakingV1(dApp, addr);
        this.setLoaded();
    }

    static availableOnChain(chain)
    {
        const addr = chain?.modules?.stakingV1;
        const result = (addr !== undefined
            && addr !== "");

        return result;
    }
}

export const useStakingV1 = () => useModule(Module_StakingV1);

const currentModule = new Module_StakingV1();
export default currentModule;