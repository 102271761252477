//components
import
{
	PageWrapper_MoonVaultV1_vaultDetails,
	MoonVaultWidgets
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVCheckModule
} from "../../dApp/components";

export const Page_earn_vaultDetails = () =>
{
    return (
		<MVPage>
            <MVCheckModule module={MoonVaultWidgets.vaultV1}>
				<PageWrapper_MoonVaultV1_vaultDetails />
			</MVCheckModule>
        </MVPage>
    );
}