import React from "react";
import ReactDOM from "react-dom";

import { App } from "@MoonLabsDev/dapp-sdk-lib";
import { MoonVaultWidgets } from "@MoonVault/dapp-widgets-lib";

import { config } from "./dApp/config";

const getWallet = () =>
{
	const searchParams = new URLSearchParams(window?.location?.search);
	return (searchParams.has("wallet")
		? searchParams.get("wallet")
		: null
	);
}

ReactDOM.render(
	<React.StrictMode>
		<App
			config={config}
			wallet={getWallet()}
			modules=
			{
				[
					"payment",
					"stakingV1",
					MoonVaultWidgets.api,
					MoonVaultWidgets.referral,
					MoonVaultWidgets.swap,
					MoonVaultWidgets.vaultV1
				]
			}
		/>
	</React.StrictMode>,
	document.getElementById("root")
);
