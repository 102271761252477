import { useEffect, useMemo, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";

//components
import
{
	Button,
	Card,
	CardBody,
	CardHeader,
	CheckTransactionButton_Balance,
	Flex,
	FormatTokenAmount,
	Grid,
	InputTokenAmount_Balance,
	Text,
	TextToggle,
	useDApp
} from "@MoonLabsDev/dapp-core-lib";
import
{
	StakingV1Details,
	StakingV1Deposit,
	StakingV1Withdraw,
} from ".";

//framework
import { useEventSubscription, MLUtils, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useStakingV1, ModuleEvents_StakingV1 } from "../../../modules";


const CardAdmin = ({farm}) =>
{
	//context
	const staking = useStakingV1();

	//state
	const [amountStr, setAmountStr] = useState(() => "");
	const amount = useMemo(() => staking.rewardToken.floatString2BN(amountStr), [amountStr]);

	//handler
	const handleStart = () =>
	{
		staking.startStaking().trySend();
	}
	const handleDepositReward = (_amount) =>
	{
		staking.depositReward(_amount).trySend();
	}
	const handleWithdrawReward = () =>
	{
		staking.emergencyRewardWithdraw().trySend();
	}

	return (
		<Card>
			<CardHeader>
				Admin
			</CardHeader>
			<CardBody>
				<Flex>
					{(new Date().getTime() < (staking.startTime * 1000)) &&
						<Button onClick={() => handleStart()}>
							Start
						</Button>
					}

					<Grid>
						<Text>
							Reward Balance:{" "}
							<FormatTokenAmount
								token={staking.rewardToken}
								value={staking.balanceOfReward}
							/>
						</Text>

						<Text>
							Remaining Rewards:{" "}
							<FormatTokenAmount
								token={staking.rewardToken}
								value={staking.remainingReward}
							/>
						</Text>

						<Text>
							Days before Rewards empty: {staking.daysBeforeRewardsEmpty}
						</Text>
					</Grid>

					<Grid>
						<Text color={3}>
							Deposit Rewards to Contract:
						</Text>

						<InputTokenAmount_Balance
							token={staking.rewardToken}
							value={amountStr}
							onChange={(e, v) => setAmountStr(v)}
						/>

						<CheckTransactionButton_Balance
							token={staking.rewardToken}
							amount={amount}
							onClick={() => handleDepositReward(amount)}
						>
							Deposit Reward
						</CheckTransactionButton_Balance>

						<Button onClick={() => handleWithdrawReward()}>
							Withdraw Rewards
						</Button>
					</Grid>
				</Flex>
			</CardBody>
		</Card>
	)

}

const StakingDetailsContent = ({farmID, onGoBackToFarms}) =>
{
	//context
	const dApp = useDApp();
	const staking = useStakingV1();

	//functions
	const getFarm = (_farm) =>
	{
		return {..._farm};
	};

	//state
	const [farm, setFarm] = useState(() => getFarm(staking.findFarm(farmID)));

	//effects
	useEffect(() =>
    {
		setFarm(getFarm(staking.findFarm(farmID)));
    }, [farmID]);
	useEventSubscription(
		[
			ModuleEvents_StakingV1.farmData,
			ModuleEvents_StakingV1.userData
		],
		() => setFarm(getFarm(staking.findFarm(farmID))),
		[farmID]
	);

	return (
		<>
			{!!farm.initialized &&
				<>
					<StakingV1Details
						farm={farm}
						onGoBack={onGoBackToFarms}
					/>

					<Grid
						cols={1}
						responsive=
						{
							{
								md:
								{
									cols: 2
								}
							}
						}
					>
						<StakingV1Deposit
							farmID={farm.id}
						/>

						<StakingV1Withdraw
							farmID={farm.id}
						/>
					</Grid>

					{MLWeb3.checkEqualAddress(staking.owner, dApp.account, false) &&
						<CardAdmin farm={farm} />
					}
				</>
			}
		</>
	);
};

export const PageWrapper_StakingV1_farmDetails = ({ farmsLink = `/earn/farms`}) =>
{
	//context
	const navigate = useNavigate();
	const [searchParams] = useSearchParams();
	const farms = useStakingV1();

	//functions
	const getStakingID = (_searchParams) =>
	{
		if (!searchParams.has("farm"))
		{
			return null;
		}

		return MLUtils.defaultNumber(parseInt(_searchParams.get("farm")), null);
	};

    //state
    const farmID = useMemo(() => getStakingID(searchParams), [searchParams]);
	const [farm, setStaking] = useState(() => farms.findFarm(farmID));

	//handler
	const onGoBackToFarms = (_farmID) =>
	{
		navigate(`${farmsLink}${_farmID === null ? "" : `#farm_${_farmID}`}`);
	};

	//effects
	useEventSubscription(
		ModuleEvents_StakingV1.farmData,
		() => setStaking(farms.findFarm(farmID)),
		[farmID]
	);

    return (
		<>
			{farm === null
				? <Button onClick={() => onGoBackToFarms(farmID)}>
					Back to Farms
				</Button>
				: <StakingDetailsContent
					farmID={farmID}
					onGoBackToFarms={onGoBackToFarms}
				/>
			}
        </>
    );
}