import { useState } from "react";

//components
import
{
	Text,
	Grid,
	Button,
	Image,
	cx,
	useEventSubscription
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { useDApp, MLFormat } from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents_Payment, usePayment } from "src/dApp/modules";

//styles
import styles from "./Payments.module.css";

export const PaymentOrganizationInfo = ({organizationId}) =>
{
	//context
	const dApp = useDApp();
	const payment = usePayment();

	//funcitons
	const getUserOrg = () =>
	{
		const o = payment.findOrCreateOrganization(organizationId);
		if (!o)
		{
			return null;
		}
		return {...o};
	};

	//state
	const [orgInfo, setOrgInfo] = useState(() => getUserOrg(organizationId));

	//effects
	useEventSubscription(ModuleEvents_Payment.orgData, () => setOrgInfo(getUserOrg(organizationId)));

	return (
		<div className={styles.orgInfo}>
			{orgInfo &&
				<Grid>
					{orgInfo.name === ""
						? MLFormat.formatAddress(orgInfo.owner, true)
						: orgInfo.name
					}
				</Grid>
			}
		</div>
	);
};