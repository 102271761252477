//components
import { GasButton } from "@MoonLabsDev/dapp-core-lib";

export const ToolMenu = (props) =>
{
	return (
		<>
			<GasButton />
		</>
	);
};