export const Filters =
[
	{
		chain: 25,
		vaults: []
	},
	{
		chain: 56,
		vaults:
		[
			40, //Kyoto: USDC-BUSD
			41, //Kyoto: BNB-BUSD
			42, //Kyoto: BTC-BUSD
			43, //Kyoto: ETH-BUSD
		]
	},
	{
		chain: 137,
		vaults: []
	},
	{
		chain: 250,
		vaults: []
	}
];