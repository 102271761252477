//framework
import { DApp, MLWeb3, MLMultiCall, MLFormat, Web3Transaction, MLUtils } from "@MoonLabsDev/dapp-core-lib";
import { ModuleEvents } from "../modules/Module_Payment";

//contracts
import ABI_Payment from "../abi/Payment";
import ABI_Organization from "../abi/Organization";

export const PaymentStatus =
{
	NONE: 0,
	CREATED: 1,
	CANCELLED: 2,
	PAID : 3
};

export class Payment
{
	////////////////////////////////////

	constructor(dapp, address)
	{
		//init
		this.initialized = false;
		this.initializedUser = false;
        this.dapp = dapp;

		//values
		this.address = address;
		this.organizationManagerAddress = null;

		//data
		this.showNewCreatedInvoice = false;
		this.percentFactor = 100;
		this.payerFee = 0;
		this.receiverFee = 0;
		this.totalInvoices = 0;
		this.issuerId = null;
		this.totalPaidInvoices = 0;
		this.globalInvoices = [];
		this.invoices = [];

		//user data
		this.userInvoicePaidCount = 0;
		this.userInvoiceCreatedCount = 0;
		this.userPaidInvoiceCount = 0;
		this.userInvoicesPaid = [];
		this.userInvoicesCreated = [];
		this.userPaidInvoices = [];

		//organizations
		this.organizations = [];
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `Payment failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_Payment, this.address).methods;
    }

	getContractOrganization(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_Organization, this.organizationManagerAddress).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_Payment,
            _calls
        );
    }

	makeMultiCallOrganization(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.organizationManagerAddress,
            ABI_Organization,
            _calls
        );
    }

	/////////////////////////
    // Init
    /////////////////////////

    async batch_init()
    {
		if (this.initialized)
		{
			return;
		}
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[Payment] init",
            "Payment: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
			percentFactor: { function: "PERCENT_FACTOR" },
            payerFeePercent: { function: "payerFeePercent" },
			receiverFeePercent: { function: "receiverFeePercent" },
			totalInvoices:  { function: "totalInvoices" },
			totalPaidInvoices:  { function: "totalPaidInvoices" },
			organizationManagerAddress: { function: "organizations" },

			...(this.dapp.account !== null &&
				{
					issuerId: { function: "getUserIssuerId", parameters: [this.dapp.account] }
				}
			)
        });
    }

    async processRequest_init(_data)
    {
        this.percentFactor = MLWeb3.toBN(_data.percentFactor);
		this.payerFee = MLWeb3.getPercent(MLWeb3.toBN(_data.payerFeePercent), this.percentFactor);
		this.receiverFee = MLWeb3.getPercent(MLWeb3.toBN(_data.receiverFeePercent), this.percentFactor);
		this.totalInvoices = parseInt(this.totalInvoices);
		this.totalPaidInvoices = parseInt(this.totalPaidInvoices);
		this.organizationManagerAddress = (MLWeb3.isZeroAddress(_data.organizationManagerAddress) ? null : _data.organizationManagerAddress);
		if (!!_data.issuerId)
		{
			console.warn(`Payment IssuerID: ${_data.issuerId}`);
			this.issuerId = MLWeb3.toBN(_data.issuerId);
			if (this.issuerId.cmp(MLWeb3.toBN(0)) === -1)
			{
				//register organization
				this.findOrCreateOrganization(-parseInt(this.issuerId.toString(10)));
			}
		}

		this.initialized = true;

        //event
        MLUtils.dispatchEvent(ModuleEvents.init);
    }

	/////////////////////////
    // Organization
    /////////////////////////

    async batch_organizationInfo()
    {
		await this.batch_init();
		if (this.organizationManagerAddress === null)
		{
			return;
		}
		const filtered = this.organizations.filter(o => o.load);
        await this.dapp.batchCall(
            filtered,
            (o) => this.makeRequest_organizationInfo(o),
            (o, r) => this.processRequest_organizationInfo(o, r),
            false,
            "[Payment] organizationInfo",
            "Payment: organizationInfo"
        );
    }

    makeRequest_organizationInfo(_org)
    {
        return this.makeMultiCallOrganization(
        {
			info: { function: "getOrganization", parameters: [_org.id] }
        });
    }

    async processRequest_organizationInfo(_org, _data)
    {
        _org.name = _data.info.name;
		_org.logo = _data.info.logo;
		_org.verified = _data.info.verified;
		_org.owner = _data.info.owner;
		_org.paymentReceiver = (MLWeb3.isZeroAddress(_data.info.paymentReceiver) ? "" : _data.info.paymentReceiver);
		_org.tipReceiver = (MLWeb3.isZeroAddress(_data.info.tipReceiver) ? "" : _data.info.tipReceiver);

		//process
		_org.load = false;
		_org.initialized = true;

        //event
        MLUtils.dispatchEvent(ModuleEvents.orgData);
    }

	/////////////////////////
    // Invoice
    /////////////////////////

    async batch_invoiceInfo()
    {
		await this.batch_init();
		const filtered = this.invoices.filter(i => i.load);
        await this.dapp.batchCall(
            filtered,
            (o) => this.makeRequest_invoiceInfo(o),
            (o, r) => this.processRequest_invoiceInfo(o, r),
            false,
            "[Payment] invoiceInfo",
            "Payment: invoiceInfo"
        );
    }

    makeRequest_invoiceInfo(_invoice)
    {
        return this.makeMultiCall(
        {
			info: { function: "getGlobalInvoice", parameters: [_invoice.globalId] }
        });
    }

    async processRequest_invoiceInfo(_invoice, _data)
    {
		this.createOrUpdateInvoice(_data.info);
    }

	/////////////////////////
    // UserBase
    /////////////////////////

    async batch_userInfoBase()
    {
		await this.batch_init();
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_userInfoBase(),
            (o, r) => o.processRequest_userInfoBase(r),
            false,
            "[Payment] userInfoBase",
            "Payment: userInfoBase"
        );
    }

    makeRequest_userInfoBase()
    {
        return this.makeMultiCall(
        {
			paidInvoicesCount: { function: "getPaidInvoiceCount", parameters: [this.dapp.account] },
            invoiceListLength: { function: "getInvoiceListLength", parameters: [this.getIssuerId()] },
        });
    }

    async processRequest_userInfoBase(_data)
    {
        this.userPaidInvoiceCount = parseInt(_data.paidInvoicesCount);
		this.userInvoiceCreatedCount = parseInt(_data.invoiceListLength.created);
		this.userInvoicePaidCount = parseInt(_data.invoiceListLength.paid);

        //event
        MLUtils.dispatchEvent(ModuleEvents.userData);
    }

	/////////////////////////
    // User
    /////////////////////////

    async batch_userInfo()
    {
		await this.batch_init();
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_userInfo(),
            (o, r) => o.processRequest_userInfo(r),
            false,
            "[Payment] userInfo",
            "Payment: userInfo"
        );
    }

    makeRequest_userInfo()
    {
        return this.makeMultiCall(
        {
			paidInvoices:
			{
				function: "getPaidInvoiceRange",
				parameters:
				[
					this.dapp.account,
					0,
					this.userPaidInvoiceCount
				]
			},
            invoicesCreated:
			{
				function: "getInvoiceRange",
				parameters:
				[
					this.getIssuerId(),
					true,
					0,
					this.userInvoiceCreatedCount
				]
			},
			invoicesPaid:
			{
				function: "getInvoiceRange",
				parameters:
				[
					this.getIssuerId(),
					false,
					0,
					this.userInvoicePaidCount
				]
			}
        });
    }

    async processRequest_userInfo(_data)
    {
        _data.paidInvoices.forEach(i => this.createOrUpdateInvoice(i));
		_data.invoicesCreated.forEach(i => this.createOrUpdateInvoice(i));
		_data.invoicesPaid.forEach(i => this.createOrUpdateInvoice(i));

		//process
		this.initializedUser = true;

        //event
        MLUtils.dispatchEvent(ModuleEvents.userData);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	isOrganizationOwner()
	{
		return (this.isOrganizationIssuer()
			&& MLWeb3.checkEqualAddress(this.findOrCreateOrganization(this.getIssuerId()).owner, this.dapp.account))
	}

	getIssuerId()
	{
		if (this.isOrganizationIssuer())
		{
			return parseInt(this.issuerId.toString(10));
		}
		return "0x" + this.issuerId.toString(16);
	}

	isOrganizationIssuer()
	{
		if (!this.issuerId)
		{
			return false;
		}
		return (this.issuerId.cmp(MLWeb3.toBN(0)) === -1);
	}

	findGlobalInvoice(_globalInvoiceId)
	{
		return this.invoices.find(i => i.globalId === _globalInvoiceId) || null;
	}

	findInvoice(_organization, _id)
	{
		return this.invoices.find(i => i.orgId === _organization && i.id === _id) || null;
	}

	getUserOrganization()
	{
		return (this.isOrganizationIssuer()
			? this.findOrCreateOrganization(this.getIssuerId())
			: null
		);
	}

	findOrCreateOrganization(_id)
	{
		_id = (_id < 0 ? -_id : _id);
		let o = this.organizations.find(o => o.id === _id) || null;
		if (!o)
		{
			o = {
				id: _id,
				load: true,
				initialized: false,

				owner: null,
				name: "",
				logo: "",
				verified: false,
				paymentReceiver: "",
				tipReceiver: "",
				members: []
			};
			this.organizations.push(o);
		}
		return o;
	}

	pollGlobalInvoice(_globalInvoiceId, _poll)
	{
		let i = this.findGlobalInvoice(_globalInvoiceId);
		if (!i)
		{
			i = {
				globalId: _globalInvoiceId,
				load: true,
				initialized: false,
				poll: _poll
			}
			this.invoices.push(i);
		}
		i.poll = (_poll === true);
	}

	createOrUpdateInvoice(_invoiceData, _issuerId = null)
	{
		let i = this.findGlobalInvoice(parseInt(_invoiceData.globalInvoiceId));
		if (!i)
		{
			//create
			i = {
				id: parseInt(_invoiceData.id),
				issuerId: MLWeb3.toBN(_invoiceData.issuerId),
				globalId: parseInt(_invoiceData.globalInvoiceId),
				token: (MLWeb3.isZeroAddress(_invoiceData.token)
					? null
					: this.dapp.findToken(_invoiceData.token)
				),
				amount: MLWeb3.toBN(_invoiceData.amount),
				memo: _invoiceData.memo,
				creator: _invoiceData.creator,
				receiver: _invoiceData.receiver,
				createdAt: MLUtils.timestampToDate(_invoiceData.createdAt),
				show: false,
				load: true,
				initialized: false,
				poll: false
			};
			if (i.issuerId.cmp(MLWeb3.toBN(0)) === -1)
			{
				//register organization
				this.findOrCreateOrganization(-parseInt(i.issuerId.toString(10)));
			}
			this.invoices.push(i);
			if (this.showNewCreatedInvoice
				&& i.creator === this.dapp.account)
			{
				i.show = true;
				this.showNewCreatedInvoice = false;
			}
		}

		//update
		const prevStatus = i.status;
		if (!i.initialized)
		{
			i.id = parseInt(_invoiceData.id);
			i.issuerId = MLWeb3.toBN(_invoiceData.issuerId);
			i.token = (MLWeb3.isZeroAddress(_invoiceData.token)
				? null
				: this.dapp.findToken(_invoiceData.token)
			);
			i.amount = MLWeb3.toBN(_invoiceData.amount);
			i.memo = _invoiceData.memo;
			i.creator = _invoiceData.creator;
			i.receiver = _invoiceData.receiver;
			i.createdAt = MLUtils.timestampToDate(_invoiceData.createdAt);
		}
		i.status = parseInt(_invoiceData.status);
		i.payer = _invoiceData.payer;
		i.closedAt = MLUtils.timestampToDate(_invoiceData.closedAt);
		i.initialized = true;
		i.load = false;

		//check lists
		this.invoiceUpdateList(i, prevStatus);

		return i;
	}

	invoiceUpdateList(_invoice, _previousStatus)
	{
		this.invoiceModifyList(_invoice, _previousStatus, false);
		this.invoiceModifyList(_invoice, _invoice.status, true);
	}

	invoiceModifyList(_invoice, _status, _add)
	{
		if (_add
			&& MLWeb3.checkEqualAddress(_invoice.payer, this.dapp.account))
		{
			this.invoiceListAddOrRemove(this.userPaidInvoices, _invoice, _add);
		}
		if (_invoice.issuerId !== undefined
			&& _invoice.issuerId.cmp(this.issuerId) === 0)
		{
			if (_status === PaymentStatus.CREATED)
			{
				this.invoiceListAddOrRemove(this.userInvoicesCreated, _invoice, _add);
			}
			else if (_status === PaymentStatus.PAID)
			{
				this.invoiceListAddOrRemove(this.userInvoicesPaid, _invoice, _add);
			}
		}
	}

	invoiceListAddOrRemove(_list, _invoice, _add)
	{
		if (_add
			&& !_list.includes(_invoice))
		{
			_list.push(_invoice);
		}
		if (!_add
			&& _list.includes(_invoice))
		{
			_list.splice(_list.indexOf(_invoice), 1);
		}
	}

	/////////////////////////
    // Transactions
    /////////////////////////

	createInvoice(
		_token,
		_amount,
		_memo
	)
    {
		this.showNewCreatedInvoice = true;
        const con = this.getContract(true);
        return new Web3Transaction(
            con.createInvoice(
				this.issuerId,
				_token?.address || MLWeb3.getZeroAddress(),
				_amount,
				_memo
			),
            this.debugErrorString("createInvoice"),
            `Create Invoice ${MLFormat.smartFormatToken(_amount, _token || this.dapp.wrappedCoin)} ${_token?.symbol || this.dapp.coinSymbol}`
		);
    }

	cancelInvoice(_invoice)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.cancelInvoice(
				_invoice.issuerId,
				_invoice.id
			),
            this.debugErrorString("cancelInvoice"),
            `Cancel Invoice #${_invoice.id}`
		);
    }

	payInvoice(_invoice, _tipAmount)
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.payInvoice(
				_invoice.issuerId,
				_invoice.id,
				_tipAmount || MLWeb3.toBN(0)
			),
            this.debugErrorString("payInvoice"),
            `Pay Invoice #${_invoice.id}`,
			(_invoice.token === null
				? _invoice.amount
				: null
			)
		);
    }

	addMember(_member)
    {
        const con = this.getContractOrganization(true);
        return new Web3Transaction(
            con.addMember(_member),
            this.debugErrorString("addMember"),
            `Add member ${MLFormat.formatAddress(_member, true)}`,
		);
    }

	removeMember(_member)
    {
        const con = this.getContractOrganization(true);
        return new Web3Transaction(
            con.removeMember(_member),
            this.debugErrorString("removeMember"),
            `Remove member ${MLFormat.formatAddress(_member, true)}`,
		);
    }
}