//components
import { Flex } from "@MoonLabsDev/dapp-core-lib";
import QRCode from "react-qr-code";

export const QRCodeField = ({ data }) =>
{
  	return (
    	<Flex style={{ padding: "10px", background: "#fff" }}>
      		<QRCode value={data} />
    	</Flex>
  	);
};
