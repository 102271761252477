//components
import
{
	PageWrapper_MoonVaultV1_vaults,
	MoonVaultWidgets
} from "@MoonVault/dapp-widgets-lib";
import
{
	MVPage,
	MVPageHeader,
	MVCheckModule
} from "../../dApp/components";

export const Page_earn_vaults = () =>
{
    return (
		<MVPage
			header=
			{
				<MVPageHeader
					title="Vaults"
					backgroundImage="url(/assets/page/earn/vaults/header.png)"
				>
					Vaults help to increase your yield, by automating compounds for higher returns!
				</MVPageHeader>
			}
		>
            <MVCheckModule module={MoonVaultWidgets.vaultV1}>
				<PageWrapper_MoonVaultV1_vaults />
			</MVCheckModule>
        </MVPage>
    );
}