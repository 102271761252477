//components
import
{
	Text,
	Grid,
	Flex,
	TokenIcon,
	ValueTokenAmount
} from "@MoonLabsDev/dapp-core-lib";
import Icon_Complete from "./Icon_Complete";
import Icon_Pending from "./Icon_Pending";

//framework
import
{
	cx,
	useDApp,
	MLWeb3,
	MLFormat,
} from "@MoonLabsDev/dapp-core-lib";

//classes
import { PaymentStatus } from "../../../../classes/Payment";

//styles
import styles from "../Payments.module.css";

export const PaymentInvoiceListItem = ({invoice, isPaid}) =>
{
	const dApp = useDApp();

	return (
		<Flex style={{ width: "100%" }}>
			<TokenIcon
				token={invoice.token || dApp.wrappedCoin}
				style=
				{
					{
						marginLeft: 5,
						width: 32,
						height: 32
					}
				}
			/>

			<Grid gap="0">
				<Text>
					<ValueTokenAmount
						token={invoice.token}
						value={invoice.amount}
						shorten={true}
					/>
					{" "}{invoice.token?.symbol || dApp.coinSymbol}
				</Text>
				<Text
					size={-1}
					color={2}
					weight={-1}
				>
					{MLFormat.formatDateTime(invoice.createdAt, true)}
				</Text>
			</Grid>

			<Grid gap="0">
				<Text>
					{isPaid
						? MLFormat.formatAddress(invoice.receiver, true)
						: MLWeb3.isZeroAddress(invoice.payer)
							? ""
							: MLFormat.formatAddress(invoice.payer, true)
					}
				</Text>
				<Text
					size={-1}
					color={2}
					weight={-1}
				>
					{invoice.memo}
				</Text>
			</Grid>

			<div
				style=
				{
					{
						marginLeft: "auto",
						marginRight: 5
					}
				}
			>
				{invoice.status === PaymentStatus.PAID && <Icon_Complete className={styles.itemStatus} />}
				{invoice.status === PaymentStatus.CREATED && <Icon_Pending className={styles.itemStatus} />}
			</div>
		</Flex>
	);
};