import { useMemo, useState } from "react";

//components
import
{
	Card,
	CardHeader,
	CardBody,
	CardFooter,
	List,
	Text
} from "@MoonLabsDev/dapp-core-lib";
import { PaymentInvoiceListItem } from "./PaymentInvoiceListItem";
import { ModalPaymentViewInvoice, ModalPaymentQR } from "../Modals";

export const PaymentInvoiceList = ({title, invoices, isPaid, className, style}) =>
{
	//state
	const pageSize = 20;
	const [selectedInvoice, setSelectedInvoice] = useState(() => null);
	const [qrInvoice, setQRInvoice] = useState(() => null);
	const items = useMemo(() =>
	{
		const qr = invoices.find(i => i.show);
		if (qr)
		{
			qr.show = false;
			setQRInvoice(qr.globalId);
		}
		return invoices.slice(-pageSize).map(i =>
			({
				value: i.globalId,
				content: (
					<PaymentInvoiceListItem
						invoice={{...i}}
						isPaid={isPaid}
					/>
				)
			})
		);
	}, [invoices]);

	return (
		<Card
			className={className}
			style={style}
		>
			<CardHeader>
				{title}
			</CardHeader>
			<CardBody>
				{(selectedInvoice !== null && qrInvoice == null) &&
					<ModalPaymentViewInvoice
						show={selectedInvoice !== null}
						globalInvoiceId={selectedInvoice}
						onClose={() => setSelectedInvoice(null)}
						onQR={() => setQRInvoice(selectedInvoice)}
					/>
				}
				{qrInvoice !== null &&
					<ModalPaymentQR
						show={qrInvoice !== null}
						globalInvoiceId={qrInvoice}
						onClose={() => setQRInvoice(null)}
					/>
				}
				<List
					items={items}
					style={{ maxHeight: "200px" }}
					onSelect={(i) => setSelectedInvoice(i)}
				/>
			</CardBody>
			<CardFooter>
				<Text>
					Total: {invoices.length}
				</Text>
			</CardFooter>
		</Card>
	);
};