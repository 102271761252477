//components
import
{
	Card,
	CardBody,
	SelectBox,
	Input,
	CheckBox,
	Text,
	Flex,
	search as SearchIcon
} from "@MoonLabsDev/dapp-core-lib";

//classes
import { StakingV1FilterSortType } from "../../../../classes/StakingV1";

export const StakingV1Filter = ({filter, onChange}) =>
{
	//function
	const makeFilter = (_changedFilter) =>
	{
		const f =
		{
			text: "",
			onlyDeposit: false,
			hideZero: false,
			showInactive: false,
			sort: undefined,
			...filter,
			..._changedFilter
		}
		return f;
	};

	//handler
	const handleFilterChange = (_changedFilter) =>
	{
		onChange?.(makeFilter(_changedFilter));
	};

	return (
		<Card>
			<CardBody>
				<Flex style={{justifyContent: "space-between"}}>
					<Input
						grow
						startAdornment=
						{
							<SearchIcon
								style={{fill: "var(--ML_page_colorAccent)", height: "1rem"}}
							/>
						}
						value={filter?.text || ""}
						onChange={(e, v) => handleFilterChange({ text: v})}
						placeholder="Search for name"
					/>

					<Text
						size={-1}
						color={2}
						align="center"
					>
						Only
						<br />
						Deposit
					</Text>
					<CheckBox
						accentedBorder
						checked={!!filter?.onlyDeposit}
						onChange={(e, v) => handleFilterChange({ onlyDeposit: v})}
					/>

					<Text
						size={-1}
						color={2}
						align="center"
					>
						Hide 0
						<br />
						Balance
					</Text>
					<CheckBox
						accentedBorder
						checked={!!filter?.hideZero}
						onChange={(e, v) => handleFilterChange({ hideZero: v})}
					/>

					<Text
						size={-1}
						color={2}
						align="center"
					>
						Show
						<br />
						Inactive
					</Text>
					<CheckBox
						accentedBorder
						checked={!!filter?.showInactive}
						onChange={(e, v) => handleFilterChange({ showInactive: v})}
					/>

					<Text
						size={-1}
						color={2}
						align="center"
					>
						Sort by
					</Text>
					<SelectBox
						value={filter?.sort || StakingV1FilterSortType.ASSET}
						options=
						{
							[
								{
									value: StakingV1FilterSortType.ID,
									text: "ID"
								},
								{
									value: StakingV1FilterSortType.APR,
									text: "APR"
								},
								{
									value: StakingV1FilterSortType.ASSET,
									text: "Asset"
								}
							]
						}
						onSelect={v => handleFilterChange({ sort: v})}
					/>
				</Flex>
			</CardBody>
		</Card>
	);
};