import { useState, useMemo } from "react";

//components
import
{
    Modal,
    Grid,
	Text,
	Input,
    InputTokenAmount,
	ModalButtonContainer,
	ModalButton,
	CheckTransactionButton,
	FormatPercent
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { Translator as t } from "@MoonLabsDev/dapp-core-lib";
import { MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//modules
import { usePayment } from "../../../../modules";

//hooks
import { useDApp } from "@MoonLabsDev/dapp-core-lib";

export const ModalPaymentCreateInvoice = ({ show, onClose }) =>
{
    //hooks
    const dApp = useDApp();
    const payment = usePayment();

    //state
    const [token, setToken] = useState(() => null);
    const [amountStr, setAmountStr] = useState(() => "");
	const [memo, setMemo] = useState(() => "");
	const amount = useMemo(() => MLWeb3.convertFloatString_TokenBN(amountStr, token || dApp.wrappedCoin), [amountStr, token]);

	//handler
    const handleClose = () =>
    {
        onClose?.();
    };
    const handleCreate = () =>
    {
		payment.createInvoice(
			token,
			amount,
			memo
		).send();
        handleClose();
    };

    return (
        <Modal
            show={show}
            header={t("modal_paymentCreate_title")}
            footer=
            {
                <ModalButtonContainer>
                    <CheckTransactionButton
						as={ModalButton}
						amount={amount}
						onClick={() => handleCreate()}
					>
                        {t("modal_paymentCreate_create")}
                    </CheckTransactionButton>
					<ModalButton
						onClick={() => handleClose()}
						color={-1}
					>
                        {t("cancel")}
                    </ModalButton>
                </ModalButtonContainer>
            }
            onClose={() => handleClose()}
        >
            <Grid>
				<Grid gap="0">
					<Text size={-1}>
						Memo:
					</Text>
					<Input
						value={memo}
						placeholder={t("modal_paymentCreate_memoPlaceholder")}
						onChange={(e, v) => setMemo(v)}
					/>
				</Grid>

				<InputTokenAmount
					token={token}
					value={amountStr}
					allowTokenSelection={true}
                    showInWallet={true}
					showMax={false}
					onChange={(e, v) => setAmountStr(v)}
					onSelect={(t) => setToken(t)}
				/>

				<Text
					size={-1}
					color={2}
					align="center"
					style={{ paddingTop: 50}}
				>
					We have a fee on the received amount, of{" "}
					<FormatPercent
						value={payment.receiverFee}
						shorten={true}
					/>
					{" "}paid, when user pays the invoice.
				</Text>
            </Grid>
        </Modal>
    );
};