//framework
import
{
	DApp,
	MLWeb3,
	MLMultiCall,
	MLFormat,
	Web3Transaction,
	MLUtils
} from "@MoonLabsDev/dapp-core-lib";

//modules
import { ModuleEvents } from "../modules/Module_StakingV1";

//contracts
import ABI_StakingV1 from "../abi/StakingV1";

export const StakingV1FilterSortType =
{
	ID: "id",
	APR: "apr",
	ASSET: "asset"
};

export const StakingV1Tags =
{
	FARM: "Farm",
	POOL: "Pool",
	STABLE: "Stable",
	VESTED: "Vested",
	DEVELOPER: "Developer"
};

export class StakingV1
{
	////////////////////////////////////

	constructor(dapp, address)
	{
		//init
		this.initialized = false;
		this.initializedFarms = false;
		this.initializedUser = false;
        this.dapp = dapp;

		//values
		this.address = address;
		this.poolLength = 0;
		this.totalAllocPoint = MLWeb3.toBN(0);
		this.disableVesting = false;
		this.balanceOfReward = MLWeb3.toBN(0);
		this.remainingReward = MLWeb3.toBN(0);
		this.daysBeforeRewardsEmpty = parseInt(0);
		this.owner = null;
		this.startTime = 0;

		//data
		this.farms = [];

		//farms
		this.percentFactor = MLWeb3.toBN(100000);

		//aggregated
		this.userDepositUSD = MLWeb3.toBN(0);
		this.userPendingUSD = MLWeb3.toBN(0);
		this.totalDepositUSD = MLWeb3.toBN(0);
		this.totalPendingUSD = MLWeb3.toBN(0);
		this.dailyProfitUSD = MLWeb3.toBN(0);
		this.userAPR = 0;
		this.minAPR = 0;
		this.maxAPR = 0;
		this.roi = 0;
		this.roiAPR =
		{
			day: MLWeb3.toBN(0),
			week: MLWeb3.toBN(0),
			month: MLWeb3.toBN(0),
			year: MLWeb3.toBN(0)
		};
		this.roiAPY =
		{
			day: MLWeb3.toBN(0),
			week: MLWeb3.toBN(0),
			month: MLWeb3.toBN(0),
			year: MLWeb3.toBN(0)
		};
	}

	////////////////////////////////////

	debugErrorString(_text)
	{
		return `StakingV1 failed at: ${_text}`;
	}

    getContract(_user)
    {
        const con = DApp.selectWeb3Connection(_user);
        return new con.eth.Contract(ABI_StakingV1, this.address).methods;
    }

    makeMultiCall(_calls)
    {
        return MLMultiCall.makeMultiCallContext(
            this.address,
            ABI_StakingV1,
            _calls
        );
    }

	dispatchEvent(_name, _id)
	{
		MLUtils.dispatchEvent(_name,
			{
				...(_id !== undefined && { id: _id })
			}
		);
	}

	/////////////////////////
    // Init
    /////////////////////////

    async batch_init()
    {
		if (this.initialized)
		{
			return;
		}
        await this.dapp.batchCall(
            [this],
            (o) => o.makeRequest_init(),
            (o, r) => o.processRequest_init(r),
            false,
            "[StakingV1] init",
            "StakingV1: init"
        );
    }

    makeRequest_init()
    {
        return this.makeMultiCall(
        {
			owner: { function: "owner" },
            percentFactor: { function: "PERCENT_FACTOR" },
			poolLength: { function: "poolLength" },
			totalAllocPoint: { function: "totalAllocPoint" },
			startTime: { function: "startTime" },
			rewardToken: { function: "rewardToken" },
			disableVesting: { function: "disableVesting" },
			balanceOfReward: { function: "balanceOfReward" },
			remainingReward: { function: "remainingReward" },
			//daysBeforeRewardsEmpty: { function: "daysBeforeRewardsEmpty" },
			rewardsPerSecond: { function: "rewardsPerSecond" }
        });
    }

    async processRequest_init(_data)
    {
		this.owner = _data.owner;
		this.rewardToken = DApp.instance.findToken(_data.rewardToken);
		this.percentFactor = MLWeb3.toBN(_data.percentFactor);
		this.poolLength = parseInt(_data.poolLength);
		this.totalAllocPoint = MLWeb3.toBN(_data.totalAllocPoint);
		this.startTime = parseInt(_data.startTime);
		this.disableVesting = _data.disableVesting;
		this.balanceOfReward = MLWeb3.toBN(_data.balanceOfReward);
		this.remainingReward = MLWeb3.toBN(_data.remainingReward);
		//this.daysBeforeRewardsEmpty = parseInt(_data.daysBeforeRewardsEmpty);
		this.rewardsPerSecond = MLWeb3.toBN(_data.rewardsPerSecond);

		//process
		for (let n = 0; n < this.poolLength; n++)
		{
			this.addFarm(n);
		}

		this.initialized = true;

        //event
        this.dispatchEvent(ModuleEvents.init);
    }

	/////////////////////////
    // Farm Init
    /////////////////////////

    async batch_initFarms()
    {
		//check
		await this.batch_init();
		if (!this.initialized
			|| this.initializedFarms)
		{
			return;
		}

		//batch
		const filtered = this.farms.filter(v => !v.initialized);
        await this.dapp.batchCall(
            filtered,
            (o) => this.makeRequest_initFarm(o),
            (o, r) => this.processRequest_initFarm(o, r),
            false,
            "[StakingV1] initFarm",
            "StakingV1: initFarm"
        );

		//process
		this.initializedFarms = true;
		this.dispatchEvent(ModuleEvents.initFarms);
    }

    makeRequest_initFarm(_farm)
    {
        return this.makeMultiCall(
        {
            poolInfo: { function: "poolInfo", parameters: [_farm.id] }
        });
    }

    async processRequest_initFarm(_farm, _data)
    {
		_farm.depositToken = DApp.instance.findToken(_data.poolInfo.depositToken);
		_farm.allocPoint = MLWeb3.toBN(_data.poolInfo.allocPoint);
		_farm.poolShare = MLWeb3.getPercent(MLWeb3.toBN(_data.poolInfo.allocPoint), this.totalAllocPoint);
		_farm.vestingPeriod = parseInt(_data.poolInfo.vestingPeriod);
		_farm.totalDeposit = MLWeb3.toBN(_data.poolInfo.totalDeposit);
		_farm.vested = (!this.disableVesting
			&& _farm.allocPoint > 0
			&& _farm.vestingPeriod > 0
		);
		this.generateFarmTags(_farm);

		_farm.initialized = true;
    }

	/////////////////////////
    // Farm Data
    /////////////////////////

    async batch_farmData()
    {
		//check
		await this.batch_initFarms();
		if (!this.initializedFarms)
		{
			return;
		}

		//batch
		const filtered = this.farms.filter(v => v.initialized);
        await this.dapp.batchCall(
            filtered,
            (o) => this.makeRequest_farmData(o),
            (o, r) => this.processRequest_farmData(o, r),
            false,
            "[StakingV1] farmData",
            "StakingV1: farmData"
        );
    }

    makeRequest_farmData(_farm)
    {
        return this.makeMultiCall(
		{
			poolInfo: { function: "poolInfo", parameters: [_farm.id] }
		});
    }

    async processRequest_farmData(_farm, _data)
    {
		_farm.totalDeposit = MLWeb3.toBN(_data.poolInfo.totalDeposit);

		//process
		_farm.totalDepositUSD = _farm.depositToken.getPriceUSDForAmount(_farm.totalDeposit);
		this.calculateAPR(_farm);

		//events
		this.dispatchEvent(ModuleEvents.farmData, _farm.id);
    }

	/////////////////////////
    // User
    /////////////////////////

	async batch_userData()
    {
		//check
		await this.batch_initFarms();
		if (!this.initializedFarms
			|| DApp.instance.account === null)
		{
			return;
		}

		//batch
		const filtered = this.farms.filter(v => v.initialized);
        await this.dapp.batchCall(
            filtered,
            (o) => this.makeRequest_userData(o),
            (o, r) => this.processRequest_userData(o, r),
            false,
            "[StakingV1] userData",
            "StakingV1: userData"
        );

		//process
		this.calculateMetrics();
    }

    makeRequest_userData(_farm)
    {
        return this.makeMultiCall(
		{
			userInfo: { function: "userInfo", parameters: [_farm.id, this.dapp.account] },
			userPending: { function: "pendingRewards", parameters: [_farm.id, this.dapp.account, true] },
		});
    }

    async processRequest_userData(_farm, _data)
    {
		_farm.userDeposit = MLWeb3.toBN(_data.userInfo.amount);
		_farm.userVestingStart = (parseInt(_data.userInfo.vestingStartTime) === 0
			? null
			: new Date(parseInt(_data.userInfo.vestingStartTime) * 1000)
		);
		_farm.userClaimed = MLWeb3.toBN(_data.userInfo.totalClaimed);
		_farm.userPending = MLWeb3.toBN(_data.userPending);
		_farm.userVestedUntil = (!_farm.allocPoint.isZero() && _farm.vested && !!_farm.userVestingStart
			? new Date(parseInt(_farm.userVestingStart.getTime() + (1000 * _farm.vestingPeriod)))
			: null
		);

		//process
		this.calculateEarnings(_farm);
		_farm.userDepositUSD = _farm.depositToken.getPriceUSDForAmount(_farm.userDeposit);
		_farm.approved = _farm.depositToken.checkApproved(this.address);

		_farm.initializedUser = true;

		//events
		this.dispatchEvent(ModuleEvents.userData, _farm.id);
    }

	/////////////////////////
    // Helper
    /////////////////////////

	applyFilter_fulltextSearch(_list, _filterText)
	{
		//split
		const words = _filterText.toUpperCase().split(" ");
		const list = _list.filter(v =>
		{
			//match with logical and
			const match = words.reduce((p, c) =>
			{
				//cancel, as previous was already false
				if (!p)
				{
					return false;
				}

				//protocol & name
				if (v.host.toUpperCase().includes(c)
					|| v.name.toUpperCase().includes(c))
				{
					return true;
				}

				//asset
				const tokens =
				[
					...(v.depositToken.isLPToken()
						? [
							v.depositToken.getToken0(),
							v.depositToken.getToken1()
						]
						: [v.depositToken]
					)
				]
				for (let n = 0; n < tokens.length; n++)
				{
					const t = tokens[n];

					//symbol
					if (t.symbol.toUpperCase().includes(c))
					{
						return true;
					}
				}

				//developer
				if (!!v.developerOnly
					&& MoonAPI.instance?.isDeveloper()
					&& c.length > 2
					&& StakingV1Tags.DEVELOPER.toUpperCase().includes(c.toUpperCase()))
				{
					return true;
				}

				return false;
			}, true);
			return match;
		});
		return list;
	}

	applyFilter(_filter)
	{
		let list = [...this.farms];

		//active / inactive
		list = list.filter(v => !!v.inactive === !!_filter?.showInactive);

		//0 balance
		if (!!_filter?.hideZero)
		{
			list = list.filter(v => !v.depositToken.userBalance.isZero());
		}

		//has deposit
		if (!!_filter?.onlyDeposit)
		{
			list = list.filter(v => !v.userDeposit.isZero());
		}

		//fulltext search
		if (!MLUtils.isEmptyString(_filter?.text))
		{
			list = this.applyFilter_fulltextSearch(list, _filter.text);
		}

		//tags
		if (!!_filter?.tags?.length)
		{
			list = list.filter(v => _filter.tags.reduce((p, c) => p & v.tags.includes(c), true))
		}

		//sort
		switch (_filter?.sort)
		{
			case StakingV1FilterSortType.ID:
				list = list.sort((a, b) => a.id - b.id);
				break;

			case StakingV1FilterSortType.APR: //highest first
				list = list.sort((a, b) => b.dailyAPR - a.dailyAPR);
				break;

			default: //StakingV1FilterSortType.ASSET
				list = list.sort(this.sortBy_asset);
				break;
		}

		return list;
	}

	sortBy_asset(_a, _b)
    {
        const aT = _a.depositToken;
        const bT = _b.depositToken;

        //asset type
        const a_assetType = (aT.isLPToken() ? 1 : 0);
        const b_assetType = (bT.isLPToken() ? 1 : 0);
        if (a_assetType !== b_assetType)
        {
            return a_assetType - b_assetType;
        }

        //check
        const a_symbol = aT.getSymbolSortString();
        const b_symbol = bT.getSymbolSortString();
        if (a_symbol < b_symbol)
        {
            return -1;
        }
        else if (a_symbol > b_symbol)
        {
            return 1;
        }

        return 0;
    }

	addFarm(_id)
	{
		if (!!this.findFarm(_id))
		{
			return;
		}

		//create farm
		const v =
		{
			//from config
			id: _id,
			depositToken: null,
			rewardToken: this.rewardToken,

			//init
			initialized: false,
			initializedUser: false,

			//base
			vested: false,
			vestingTime: 0,
			totalDeposit: MLWeb3.toBN(0),
			totalPending: MLWeb3.toBN(0),

			//user
			userDeposit: MLWeb3.toBN(0),
			userPending: MLWeb3.toBN(0),
			userClaimed: MLWeb3.toBN(0),

			//apr
			dailyAPR: 0,
			apr: 0,
			apy: 0,

			//usd
			compoundRewardUSD: MLWeb3.toBN(0),
			totalDepositUSD: MLWeb3.toBN(0),
			totalPendingUSD: MLWeb3.toBN(0),
			userDepositUSD: MLWeb3.toBN(0),
			userPendingUSD: MLWeb3.toBN(0),
			userClaimedUSD: MLWeb3.toBN(0),

			//meta
			userVestedUntil: null,
			tags: [],

			//db
			lastUserTransaction: null,
			profitLoss: 0
		};
		this.farms.push(v);
	}

	findFarm(_id)
	{
		return this.farms.find(v => v.id === _id) || null;
	}

	generateFarmTags(_farm)
	{
		const tags = [];

		//vested
		if (_farm.vested)
		{
			tags.push(StakingV1Tags.VESTED);
		}

		//general
		tags.push(_farm.depositToken.isLPToken()
			? StakingV1Tags.FARM
			: StakingV1Tags.POOL
		);

		//stable
		if (_farm.depositToken.isStable())
		{
			tags.push(StakingV1Tags.STABLE);
		}

		//add Tags
		tags.forEach(t =>
		{
			if (!_farm.tags.includes(t))
			{
				_farm.tags.push(t);
			}
		});
	}

	/////////////////////////
    // Data Calculation
    /////////////////////////

	calculateAPR(_farm)
	{
		const rewardS = this.rewardsPerSecond.mul(_farm.allocPoint).div(this.totalAllocPoint);
		const rewardYear = rewardS.mul(MLWeb3.toBN(60 * 60 * 24 * 365));
		const rewardYearUSD = _farm.rewardToken.getPriceUSDForAmount(rewardYear);

		_farm.apr = MLWeb3.getPercent(rewardYearUSD, _farm.totalDepositUSD);
		_farm.dailyAPR = _farm.apr / 365;
		_farm.apy = Math.pow(1 + _farm.dailyAPR, 365) - 1;
	}

	calculateEarnings(_farm)
	{
		const depositUSD = _farm.userDepositUSD;
		const dailyUSD = MLWeb3.getBNPercentShare(depositUSD, _farm.dailyAPR);

		//apr
		_farm.roiAPR = { day: dailyUSD };
		this.calcTimeValues(_farm.roiAPR, (v, t) => v.mul(MLWeb3.toBN(t)));

		//apy
		_farm.roiAPY = { day: dailyUSD };
		//this.calcTimeValues(_farm.roiAPY, (v, t) => MLWeb3.getBNPercentShare(v, Math.pow(1 + _farm.dailyAPR, t) - 1));
	}

	calcTimeValues(_data, _fn)
	{
		_data.week = _fn(_data.day, 7);
		_data.month = _fn(_data.day, 30);
		_data.year = _fn(_data.day, 365);
	}

	calculateMetrics()
	{
		//deposit / pending
		this.userDepositUSD = this.farms.reduce((p, c) => p.add(c.userDepositUSD), MLWeb3.toBN(0));
		this.userPendingUSD = this.farms.reduce((p, c) => p.add(c.userPendingUSD), MLWeb3.toBN(0));
		this.totalDepositUSD = this.farms.reduce((p, c) => p.add(c.totalDepositUSD), MLWeb3.toBN(0));
		this.totalPendingUSD = this.farms.reduce((p, c) => p.add(c.totalPendingUSD), MLWeb3.toBN(0));

		//apr
		this.roiAPR = { day: this.farms.reduce((p, c) => p.add(c.roiAPR.day), MLWeb3.toBN(0)) };
		this.calcTimeValues(this.roiAPR, (v, t) => v.mul(MLWeb3.toBN(t)));
		this.userAPR = MLWeb3.getPercent(this.roiAPR.year, this.userDepositUSD);
		this.minAPR = this.farms.reduce((p, c) => Math.min(p, c.apr), this.farms[0]?.apr || 0);
		this.maxAPR = this.farms.reduce((p, c) => Math.max(p, c.apr), this.farms[0]?.apr || 0);

		//apy
		/*
		this.roiAPY =
		{
			day: this.farms.reduce((p, c) => p.add(c.roiAPY.day), MLWeb3.toBN(0)),
			week: this.farms.reduce((p, c) => p.add(c.roiAPY.week), MLWeb3.toBN(0)),
			month: this.farms.reduce((p, c) => p.add(c.roiAPY.month), MLWeb3.toBN(0)),
			year: this.farms.reduce((p, c) => p.add(c.roiAPY.year), MLWeb3.toBN(0))
		};
		this.apy = MLWeb3.getPercent(this.roiAPY.year, this.userDepositUSD);
		*/
	}

	calcUserProfitLoss(_farm)
	{
		const lastTx = _farm.lastUserTransaction;
		if (_farm.lastUserTransaction === null)
		{
			_farm.profitLoss = 0;
			return;
		}

		_farm.profitLoss = this.calculateProfitLoss(
			_farm.userDeposit,
			MLWeb3.toBN("0x" + lastTx.userDepositSum),
			MLWeb3.toBN("0x" + lastTx.userWithdrawSum),
			MLWeb3.toBN("0x" + lastTx.userReinvestedSum)
		);
	}

	/////////////////////////
    // Transactions
    /////////////////////////

	deposit(_farmID, _amount)
    {
		const farm = this.findFarm(_farmID);
        const con = this.getContract(true);
        return new Web3Transaction(
            con.deposit(
				_farmID,
				_amount
			),
            this.debugErrorString("deposit"),
            `Deposit ${MLFormat.smartFormatToken(_amount, farm.depositToken)} ${farm.depositToken.getFullName()}`
		);
    }

	withdraw(_farmID, _amount)
    {
		const farm = this.findFarm(_farmID);
        const con = this.getContract(true);
        return new Web3Transaction(
            con.withdraw(
				_farmID,
				_amount
			),
            this.debugErrorString("withdraw"),
            `Withdraw ${MLFormat.smartFormatToken(_amount, farm.depositToken)} ${farm.depositToken.getFullName()}`
		);
    }

	startStaking()
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.startStaking(),
            this.debugErrorString("start"),
            `Start Staking`
		);
    }

	emergencyRewardWithdraw()
    {
        const con = this.getContract(true);
        return new Web3Transaction(
            con.emergencyRewardWithdraw(),
            this.debugErrorString("withdrawReward"),
            `Reward Withdraw`
		);
    }

	depositReward(_amount)
    {
        return this.rewardToken.transfer(this.address, _amount);
    }
}