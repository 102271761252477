import { useState, useMemo, useEffect } from "react";

//components
import
{
	Card,
	CardBody,
	Text,
	Flex,
	FormatFiatAmount
} from "@MoonLabsDev/dapp-core-lib";

//framework
import { useEventSubscription, MLWeb3 } from "@MoonLabsDev/dapp-core-lib";

//modules
import { useStakingV1, ModuleEvents_StakingV1 } from "../../../../modules";

export const StakingV1MetricType =
{
	USER_DEPOSIT: "userDeposit",
	USER_REWARDS_DAY: "userRewards_day",
	USER_REWARDS_WEEK: "userRewards_week",
	USER_REWARDS_MONTH: "userRewards_month",
	USER_REWARDS_YEAR: "userRewards_year"
};

export const StakingV1Metric = ({type}) =>
{
	//context
	const staking = useStakingV1();

	//functions
	const getValue = (_type) =>
	{
		switch (_type)
		{
			case StakingV1MetricType.USER_DEPOSIT:
				return staking.userDepositUSD;

			case StakingV1MetricType.USER_REWARDS_DAY:
				return staking.roiAPR.day;

			case StakingV1MetricType.USER_REWARDS_WEEK:
				return staking.roiAPR.week;

			case StakingV1MetricType.USER_REWARDS_MONTH:
				return staking.roiAPR.month;

			case StakingV1MetricType.USER_REWARDS_YEAR:
				return staking.roiAPR.year;
		}

		return MLWeb3.toBN(0);
	};
	const getTitle = (_type) =>
	{
		switch (_type)
		{
			case StakingV1MetricType.USER_DEPOSIT:
				return "Your Deposits";

			case StakingV1MetricType.USER_REWARDS_DAY:
				return "Your Daily Yield";

			case StakingV1MetricType.USER_REWARDS_WEEK:
				return "Your Weekly Yield";

			case StakingV1MetricType.USER_REWARDS_MONTH:
				return "Your Monthly Yield";

			case StakingV1MetricType.USER_REWARDS_YEAR:
				return "Your Yearly Yield";
		}

		return "";
	};

	//state
	const [value, setValue] = useState(() => getValue(type));
	const title = useMemo(() => getTitle(type), [type]);

	//effects
	useEffect(() => setValue(getValue(type)), [staking]);
	useEventSubscription([ModuleEvents_StakingV1.userData, ModuleEvents_StakingV1.dbData], () => setValue(getValue(type)), [type, staking]);

	return (
		<Card>
			<CardBody>
				<Flex justify="space-between">
					<Text
						color={1}
						size={-1}
					>
						{title}:
					</Text>

					<Text
						color={3}
						size={-1}
					>
						<FormatFiatAmount
							value={value}
						/>
					</Text>
				</Flex>
			</CardBody>
		</Card>
	);
};